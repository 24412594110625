<route>
{
  "meta": {
    "permission": [
      "expenses.view_expense"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <i-date-picker
          v-model="start"
          :label="$t('start_date')"
          color="primary"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <i-date-picker
          v-model="end"
          :label="$t('end_date')"
          color="primary"
          :min="start"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="account"
          :name="$t('account')"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            v-model="form.account"
            item-text="name"
            item-value="pk"
            :items="accounts"
            :label="$t('account')"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="category"
          :name="$tc('category', 1)"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            v-model="form.category"
            item-text="name"
            item-value="pk"
            :items="categorys"
            :label="$tc('category', 1)"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="expense_type"
          :name="$t('expense_type')"
          v-slot="{ errors }"
        >
          <v-select
            autocomplete="off"
            outlined
            :error-messages="errors[0]"
            v-model="form.recurring"
            item-text="name"
            item-value="pk"
            :items="expense_type"
            :label="$t('expense_type')"
          ></v-select>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn color="secondary" @click="clear()">
          {{ $t('clear') }}
        </v-btn>
      </v-col>
    </v-row>
    <i-table
      :title="$tc('expense', 2)"
      :headers="headers"
      app="expenses.expense"
      api="expense"
      :opt="{
        date_from: this.form.date_from,
        date_to: this.form.date_to,
        account: this.form.account,
        category: this.form.category,
        recurring: this.form.recurring
      }"
      dontCreate
      dontRemove
      dontEdit
      dontSearch
    >
      <template v-slot:item.date="{ item }">
        <span v-text="item.date != null ? dateFormat(item.date) : ''"></span>
      </template>
      <template v-slot:item.recurrent="{ item }">
        <span v-text="item.recurrent ? $t('yes') : $t('No')"></span>
      </template>
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $tc('expense', 2) })"
          color="text-dark"
          @click="getExpensesExcel()"
        >
        </i-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      modalBD: false,
      modalBD2: false,
      start: '',
      end: '',
      form: {
        date_from: '',
        date_to: '',
        account: '',
        category: '',
        recurring: null
      },
      accounts: [],
      categorys: [],
      expense_type: [
        { pk: null, name: this.$t('all') },
        { pk: false, name: this.$t('expenses_regular') },
        { pk: true, name: this.$t('expenses_recurring') }
      ]
    }
  },
  mounted() {
    this.getAccounts()
    this.getCategorys()
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc('category', 1),
          value: 'category.name'
        },
        {
          text: this.$t('account'),
          value: 'account.name'
        },
        {
          text: this.$t('date'),
          value: 'date'
        },
        {
          text: this.$t('operator'),
          value: 'operator'
        },
        {
          text: this.$t('description'),
          value: 'description'
        },
        {
          text: this.$tc('recurrent', 0),
          value: 'recurrent'
        },
        {
          text: this.$tc('cost', 1),
          value: 'cost'
        },
        {
          text: this.$t('reference'),
          value: 'reference'
        }
      ]
    }
  },
  methods: {
    getExpensesExcel() {
      this.downloadFile({
        api: 'expense',
        method: 'excel',
        filename: 'Expenses',
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params:
            '?account=' +
            this.form.account +
            '&date_from=' +
            this.form.date_from +
            '&date_to=' +
            this.form.date_to +
            '&category=' +
            this.form.category +
            '&recurring=' +
            this.form.recurring
        }
      })
    },
    getAccounts() {
      this.$api.expense.account.list({}).then((response) => {
        this.accounts = response.data.results
      })
    },
    getCategorys() {
      this.$api.expense.category.list({}).then((response) => {
        this.categorys = response.data.results
      })
    },
    clear() {
      this.form = {
        date_from: '',
        date_to: '',
        account: '',
        category: '',
        recurring: null
      }
      this.start = ''
      this.end = ''
    }
  },
  watch: {
    start: {
      handler(val) {
        if (val != null && val != '' && val != undefined) {
          this.form.date_from = this.dateFormat(val)
        }
      }
    },
    end: {
      handler(val) {
        if (val != null && val != '' && val != undefined) {
          this.form.date_to = this.dateFormat(val)
        }
      }
    }
  }
}
</script>
